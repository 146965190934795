<template>
    <div class="permission-manager">
        <div class="layout">
            <div class="main-content">
                <div class="card shadow">
                    <div class="card-header text-white">
                        <span class="h2 font-weight-bold">Implementar items al Json</span>
                    </div>
                    <div class="card-body">
                        <div v-if="jsonData" class="mb-4">
                            <span class="h3">Información del Json</span>
                            <p><strong>ID:</strong> {{ jsonData.id }}</p>
                            <p><strong>Fecha Registro:</strong> {{ jsonData.fechaRegistro }}</p>
                            <button @click="toggleJsonContent" class="btn btn-info mb-2">
                                Desplegar contenido del Json
                            </button>
                            <div v-if="showJsonContent">
                                <div class="card">
                                    <pre>{{ JSON.stringify(jsonData.registroJson, null, 2) }}</pre>
                                </div>
                            </div>
                        </div>

                        <div v-if="jsonData">
                            <span class="h3">Agregar nuevo elemento</span>
                            <form @submit.prevent="addNewElement" class="mt-3">
                                <div class="form-group">
                                    <label for="hierarchySelect">Seleccionar Jerarquía:</label>
                                    <v-select id="hierarchySelect" v-model="selectedHierarchy"
                                        :options="hierarchyOptions" @input="updateFormFields" class="style-chooser"
                                        :reduce="(option) => option.value" label="label"
                                        placeholder="-- selecciona una opción --"></v-select>
                                </div>
                                <div v-if="selectedHierarchy && selectedHierarchy !== 'category'" class="form-group">
                                    <label for="parentSelect">Seleccionar {{ parentLabel }}:</label>
                                    <v-select id="parentSelect" v-model="selectedParent" :options="parentOptions"
                                        class="style-chooser" :reduce="(option) => option.id" label="titulo"
                                        placeholder="-- selecciona una opción --"></v-select>
                                </div>
                                <div v-for="field in formFields" :key="field.name" class="form-group">
                                    <label :for="field.name">{{ field.label }}:</label>
                                    <input :type="field.type" :id="field.name" v-model="newElement[field.name]"
                                        class="form-control" required>
                                </div>
                                <button type="submit" class="btn btn-info" :disabled="!isFormValid">Agregar
                                    Elemento</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sidebar">
                <div class="card shadow hierarchy-explanation">
                    <div class="card-header bg-secondary text-white">
                        <span class="h4 mb-0 font-weight-bold">Explicación de Jerarquías</span>
                    </div>
                    <div class="card-body">
                        <div v-for="(explanation, level, index) in hierarchyExplanations" :key="level"
                            class="hierarchy-level" :style="{ '--index': index }">
                            <span class="h5 font-weight-bold">{{ explanation.title }}</span>
                            <p>{{ explanation.description }}</p>
                            <p><strong>Ejemplo:</strong> {{ explanation.example }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            jsonData: null,
            showJsonContent: false,
            selectedHierarchy: '',
            selectedParent: '',
            formFields: [],
            newElement: {},
            parentOptions: [],
            hierarchyOptions: [
                { value: 'category', label: 'Categoría' },
                { value: 'application', label: 'Aplicación' },
                { value: 'mainView', label: 'Vista Principal' },
                { value: 'subView', label: 'Sub-vista' }
            ],
            hierarchyExplanations: {
                category: {
                    title: 'Categoría',
                    description: 'El nivel más alto de la jerarquía. Representa una agrupación principal de funcionalidades.',
                    example: 'Configuración, Organización, Talento Humano'
                },
                application: {
                    title: 'Aplicación',
                    description: 'Segundo nivel de la jerarquía. Representa una aplicación o módulo dentro de una categoría.',
                    example: 'Super Admin, Data Lake, Mi compañía, Personas Evaluaciones'
                },
                mainView: {
                    title: 'Vista Principal',
                    description: 'Tercer nivel de la jerarquía. Representa una vista o página principal dentro de una aplicación.',
                    example: 'Lista de Empleados, Formulario de Evaluación, Dashboard de Nómina'
                },
                subView: {
                    title: 'Sub-vista',
                    description: 'Cuarto nivel de la jerarquía. Representa una sub-vista o componente dentro de una vista principal.',
                    example: 'Editar Persona,Editar Sucursal, Editar Departamento'
                }
            }
        };
    },
    computed: {
        parentLabel() {
            switch (this.selectedHierarchy) {
                case 'application': return 'Categoría';
                case 'mainView': return 'Aplicación';
                case 'subView': return 'Vista Principal';
                default: return '';
            }
        },
        isFormValid() {
            return this.selectedHierarchy &&
                (this.selectedHierarchy === 'category' || this.selectedParent) &&
                Object.values(this.newElement).every(value => value !== '');
        }
    },
    async mounted() {
        await this.fetchJsonData();
    },
    methods: {
        async fetchJsonData() {
            try {
                let res = await this.$store.dispatch("hl_get", {
                    path: "RegistrosJsonControlAccesos/GetLatestJson"
                });

                if (res && res.registroJson) {
                    try {
                        const parsedJson = JSON.parse(res.registroJson);
                        this.jsonData = {
                            id: res.id,
                            fechaRegistro: res.fechaRegistro,
                            registroJson: parsedJson
                        };
                    } catch (parseError) {
                        this.jsonData = null;
                    }
                } else {
                    this.jsonData = null;
                }
            } catch (error) {
                this.jsonData = null;
            }
        },
        toggleJsonContent() {
            this.showJsonContent = !this.showJsonContent;
        },
        updateFormFields() {
            this.formFields = [
                { name: 'titulo', label: 'Título', type: 'text' },
                { name: 'ruta', label: 'Ruta', type: 'text' },
                { name: 'icono', label: 'Icono', type: 'text' },
            ];

            if (this.selectedHierarchy !== 'category') {
                this.formFields.push(
                    { name: 'nivel', label: 'Nivel', type: 'text' },
                    { name: 'ordenAcceso', label: 'Orden de Acceso', type: 'number' }
                );
            }

            this.newElement = {};
            this.selectedParent = '';
            this.updateParentOptions();
        },
        updateParentOptions() {
            this.parentOptions = [];
            if (!this.jsonData || !this.jsonData.registroJson) return;

            switch (this.selectedHierarchy) {
                case 'application':
                    this.parentOptions = this.jsonData.registroJson.data.map(category => ({
                        id: category.groupId,
                        titulo: category.titulo
                    }));
                    break;
                case 'mainView':
                    this.jsonData.registroJson.data.forEach(category => {
                        category.subGrupo.forEach(app => {
                            this.parentOptions.push({
                                id: app.id,
                                titulo: `${category.titulo} > ${app.titulo}`
                            });
                        });
                    });
                    if (this.parentOptions.length === 0) {
                        this.parentOptions.push({
                            id: 'no-apps',
                            titulo: 'No hay aplicaciones disponibles'
                        });
                    }
                    break;
                case 'subView':
                    this.jsonData.registroJson.data.forEach(category => {
                        category.subGrupo.forEach(app => {
                            app.items.forEach(view => {
                                this.parentOptions.push({
                                    id: view.id,
                                    titulo: `${category.titulo} > ${app.titulo} > ${view.titulo}`
                                });
                            });
                        });
                    });
                    break;
            }
        },
        addNewElement() {
            if (!this.jsonData || !this.jsonData.registroJson) return;

            const newElement = { ...this.newElement };

            if (newElement.nivel) {
                newElement.nivel = newElement.nivel.toString();
            }

            if (newElement.ordenAcceso) {
                newElement.ordenAcceso = parseInt(newElement.ordenAcceso, 10);
            }

            this.addElementToStructure(this.jsonData.registroJson.data, newElement);

            this.newElement = {};
            this.selectedHierarchy = '';
            this.selectedParent = '';
            this.updateFormFields();
        },
        getNextId(array, prefix) {
            const maxId = Math.max(...array.map(item => {
                const idNumber = parseInt(item.id?.split('-')[1] || item.groupId?.toString().split('-')[1] || '0');
                return isNaN(idNumber) ? 0 : idNumber;
            }), 0);
            return `${prefix}-${maxId + 1}`;
        },
        addElementToStructure(data, newElement) {
            switch (this.selectedHierarchy) {
                case 'category':
                    data.push({
                        groupId: this.getNextId(data, 'cat'),
                        titulo: newElement.titulo,
                        ruta: newElement.ruta,
                        icono: newElement.icono,
                        subGrupo: []
                    });
                    break;
                case 'application':
                    {
                        const category = this.findCategory(data, this.selectedParent);
                        if (category) {
                            category.subGrupo.push({
                                id: this.getNextId(category.subGrupo, 'app'),
                                titulo: newElement.titulo,
                                icono: newElement.icono,
                                ruta: newElement.ruta,
                                nivel: newElement.nivel,
                                ordenAcceso: parseInt(newElement.ordenAcceso),
                                items: []
                            });
                        }
                    }
                    break;
                case 'mainView':
                    {
                        const app = this.findApplication(data, this.selectedParent);
                        if (app) {
                            app.items.push({
                                id: this.getNextId(app.items, 'view'),
                                titulo: newElement.titulo,
                                icono: newElement.icono,
                                ruta: newElement.ruta,
                                nivel: newElement.nivel,
                                ordenAcceso: parseInt(newElement.ordenAcceso)
                            });
                        }
                    }
                    break;
                case 'subView':
                    {
                        const view = this.findMainView(data, this.selectedParent);
                        if (view) {
                            if (!view.subItems) view.subItems = [];
                            view.subItems.push({
                                id: this.getNextId(view.subItems, 'sub'),
                                titulo: newElement.titulo,
                                icono: newElement.icono,
                                ruta: newElement.ruta,
                                nivel: newElement.nivel,
                                ordenAcceso: parseInt(newElement.ordenAcceso)
                            });
                        }
                    }
                    break;
            }
        },
        findCategory(data, groupId) {
            return data.find(category => category.groupId === groupId);
        },
        findApplication(data, appId) {
            for (let category of data) {
                const app = category.subGrupo.find(app => app.id === appId);
                if (app) return app;
            }
            return null;
        },
        findMainView(data, viewId) {
            for (let category of data) {
                for (let app of category.subGrupo) {
                    const view = app.items.find(item => item.id === viewId);
                    if (view) return view;
                }
            }
            return null;
        },
    },
};
</script>

<style scoped>
.permission-manager {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.layout {
    display: flex;
    gap: 20px;
}

.main-content {
    flex: 1;
}

.sidebar {
    width: 300px;
}
</style>